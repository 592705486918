<template>
    <a-card style="background-color: #fff;">
        <a-tabs default-active-key="" @change="callback">
            <a-tab-pane key="" tab="全部">
            </a-tab-pane>
            <a-tab-pane key="1" tab="审核中">
            </a-tab-pane>
            <a-tab-pane key="2" tab="审核通过">
            </a-tab-pane>
            <a-tab-pane key="3" tab="审核未通过">
            </a-tab-pane>
        </a-tabs>
        <a-form layout="horizontal">
            <a-row>
                <!-- <a-col :md="8" :sm="24" style="max-width: 400px;">
                    <a-form-item label="商品名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                        <a-input placeholder="请输入商品名称" v-model="queryData.name"></a-input>
                    </a-form-item>
                </a-col> -->
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                    <a-form-item label="证件种类" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                        <a-select placeholder="请选择证件种类" v-model="queryData.certificateType" option-label-prop="label">
                            <a-select-option v-for="item in manuList" :key="item.id" :value="item.id"
                                :label="item.name">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                    <a-form-item label="证件状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                        <a-select placeholder="请选择证件状态" v-model="queryData.certificateStatus" option-label-prop="label">
                            <a-select-option v-for="item in [{ id: 1, name: '有效' }, { id: 2, name: '过期' }]"
                                :key="item.id" :value="item.id" :label="item.name">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                    <a-form-item label="资质ID" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                       <a-input placeholder="请输入资质ID" v-model="queryData.id"></a-input>
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" style="max-width: 400px;">
                    <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                        <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                    </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                    <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                    <a-button type="primary" style="margin-top: 4px;margin-left: 10px;" @click="search">查询</a-button>
                </a-col>
            </a-row>
            <!-- <a-row>
                <a-button @click="showModal('add')">新增</a-button>
            </a-row> -->
            <div style="margin-bottom: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
            queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
        </a-form>
        <div>
            <a-table :scroll="{ x: 1500 }" bordered :columns="columns" :dataSource="dataSource" :loading="tableLoading"
                :pagination="false">
                <template slot="fileUrl" slot-scope="record,text">
                    <div v-for="item in text.qualificationFileList" :key="item.id">
                        <p v-for="it in item.fileUrlList" :key="it" style="margin:0;padding:0">
                            <a @click="fileLink(it)">{{ item.certificateTypeDesc }}</a>
                        </p>
                        <!-- <a v-if="item.fileUrl" @click="fileLink(item.fileUrl)">{{ item.certificateTypeDesc }}</a>  -->
                    </div>
                </template>

                <div slot="action" slot-scope="record">
                    <a v-if="record.auditStatus != 1" @click="showModal('edit', record)"
                        style="margin-left: 5px;">编辑</a>
                    <a v-if="record.auditStatus == 1" style="margin-left: 5px;color:#ccc;">编辑</a>
                </div>
                <template slot="certificateTypeDesc" slot-scope="record,text">
                    <div>{{ text.qualificationFileList[0].certificateTypeDesc }}</div>
                </template>
                <template slot="fileUrlId" slot-scope="record,text">
                    <div>{{ text.qualificationFileList[0].id }}</div>
                </template>
                
                <!-- <template slot="certificateStatusDesc" slot-scope="record,text">
                    <div>{{ text.qualificationFileList[0].certificateStatusDesc }}</div>
                </template> -->

                <template slot="qualificationFileList" slot-scope="record,text">
                    {{ text.qualificationFileList[0].certificateStatusDesc }}
                    <div v-if="text.qualificationFileList.length > 1">{{
            text.qualificationFileList[1].certificateStatusDesc }}</div>
                </template>

                <template slot="startTime" slot-scope="record,text">
                    <div v-if="text.qualificationFileList[0]">{{ formatDate(text.qualificationFileList[0].startTime) }}
                    </div>
                    <div v-else>{{ formatDate(text.qualificationFileList[1].startTime) }}</div>
                </template>
                <template slot="endTime" slot-scope="record,text">
                    <span v-if="text.qualificationFileList[0].endTime != '4070880000000'">{{
            formatDate(text.qualificationFileList[0].endTime) }}</span>
                    <span v-else>长期</span>
                </template>
                <template slot="createTime" slot-scope="record">{{ formatDateMin(record) }}</template>
                <template slot="modifyTime" slot-scope="record"> {{ formatDateMin(record) }}</template>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <credentiaModal v-if="isModalVisible" :visible="isModalVisible" :confirmLoading="confirmLoading"
            :formData="formData" @cancel="handleCancel" @submit="submit" />
    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import credentiaModal from '../components/credentiaModal.vue'
const columns = [
    { title: '序号', width: 80, customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '商品名称', dataIndex: 'goodsName', align: 'center' },
    { title: '证件种类', dataIndex: 'certificateTypeDesc', align: 'center', scopedSlots: { customRender: 'certificateTypeDesc' } },
    { title: '商品编码', dataIndex: 'name', align: 'center' },
    { title: '资质文件', dataIndex: 'fileUrl', align: 'center', scopedSlots: { customRender: 'fileUrl' } },
    { title: '资质ID', dataIndex: 'id', align: 'center' },
    { title: '有效开始时间', dataIndex: 'startTime', align: 'center', scopedSlots: { customRender: 'startTime' } },
    { title: '有效结束时间', dataIndex: 'endTime', align: 'center', scopedSlots: { customRender: 'endTime' } },
    { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
    { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '证件状态', dataIndex: 'qualificationFileList', align: 'center', scopedSlots: { customRender: 'qualificationFileList' } },
    { title: '审核状态', dataIndex: 'auditStatusDesc', align: 'center', },
    { title: '驳回原因', dataIndex: 'auditReason', align: 'center', },
    // { title: '操作', width: 80, align: 'center', scopedSlots: { customRender: 'action' }, fixed: 'right' },
]

export default {
    name: 'QueryList',
    components: { credentiaModal },
    data() {
        return {
            moment,
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                type: 400
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            currentPage: 1,
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {},
            manuList: [{ id: 401, name: '产品质检报告' }, { id: 402, name: '报关单' }, { id: 403, name: '入境检验检疫证明' }],
            userInfo: {}
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        callback(e) {
            this.queryData.auditStatus = e;
            this.getData();
        },
        dateChange(date, dateString) {
            this.dateValue = date;
            this.queryData.dateMin = dateString[0]
            this.queryData.dateMax = dateString[1]
        },
        fileLink(url) {
            window.open(url, '_blank')
        },
        showModal(type, data) {
            // 根据类型区分是新增还是编辑
            if (type === 'add') {
                this.formData = {
                    isImport: 0,
                    qualificationFileList: [{
                        startTime: '',
                        endTime: '',
                        fileUrl: ''
                    }]
                }
                this.isModalVisible = true; // 打开模态框
            } else {
                // this.formData = JSON.parse(JSON.stringify(data));
                this.getUserInfo(data.id)
            }
        },
        getUserInfo(id) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/detail/' + id, 'post', {}).then(res => {
                this.formData = res.data.data;
                this.isModalVisible = true;
            })
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        submit(data) {
            // 100 公司资质 200 品牌资质 300厂商资质  分类500 商品资质400
            data.type = 400;
            if (data.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/update', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/save', 'post', data).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                        this.formData = {}
                    }
                })
            }
        },
        updateStatus(id, status) {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/warehouse/updateStatus', 'post', {
                id, status
            }).then(res => {
                if (res.data.code == 200) {
                    this.getData();
                    this.$message.success('操作成功！')
                    this.isModalVisible = false;
                }
            })

        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                type: 400,
                auditStatus: this.queryData.auditStatus
            }
            this.dateValue = ''
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/qualification/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    data.data.forEach(item => {
                        item.qualificationFileList.forEach(it => {
                            if (it.fileUrl) {
                                it.fileUrlList = it.fileUrl.split(',')
                            }
                        })
                    })
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            // const hours = date.getHours().toString().padStart(2, '0');
            // const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            // let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            let result = year + '-' + month + '-' + day
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>